import React from 'react';
import Footer from '@components/Footer';
import Header from '@components/Header';
import PageHeader from '@components/PageHeader';
import IndexLayout from '@layouts/index';

import './updates.scss';
import UpdatesItem from '@components/UpdatesItem';
import VersionItem from '@components/VersionItem';

const Updates: React.FC = () => (
  <IndexLayout
    pageName="updates"
    helmet={{
      title: 'Updates - TileDB',
      description: 'Stay tuned for TileDB updates, including open-source releases, feature highlights, and other product news.',
    }}
  >
    <Header />
    <PageHeader title="Updates" />
    <main className="main">
      <UpdatesItem
        title="TileDB Embedded v2.3"
        date={{
          year: 2021,
          month: 6,
          day: 8,
        }}
        linkLabel="Learn more"
        link="https://github.com/TileDB-Inc/TileDB/releases/tag/2.3.0"
      >
        <VersionItem
          title="Pushdown of attribute filtering"
          description="TileDB has always supported NumPy-like slicing across multiple dimensions. These dimensions are defined in advance when creating TileDB arrays. Now, users have the option to further refine results at query-time by pushing down attribute filtering to the storage engine. Filter by any number of attribute values contained within the array."
        />
        <VersionItem
          title="S3 server-side encryption"
          description={
            <>
              This release adds a new configuration parameter to specify the server-side encryption algorithm to use when working with
              TileDB arrays on Amazon S3. Valid options are <strong>aes256</strong> (Amazon S3-managed encryption keys) and{' '}
              <strong>kms</strong> (AWS Key Management Service). In a related update, this release also includes AWS IAM{' '}
              <strong>AssumeRole</strong> support with automatic token refresh.
            </>
          }
        />
        <VersionItem
          title="More options for time"
          description="NumPy datetime data types have long been supported as dimensions in sparse TileDB arrays. This release adds support for time data types (without associated dates) as both dimensions and attributes."
        />
        <VersionItem
          title="New Hilbert layout"
          description="This release adds a new Hilbert layout for efficient space-filling-curve ordering of cells. It also fixes some previous issues with partitioning."
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Embedded v2.2"
        date={{
          year: 2021,
          month: 1,
          day: 8,
        }}
        linkLabel="Learn more"
        link="https://github.com/TileDB-Inc/TileDB/releases/tag/2.2.1"
      >
        <VersionItem
          title="Zero-copy import/export via Arrow"
          description="The TileDB adapter for Apache Arrow now supports zero-copy import/export for fast data exchange and efficient use of memory."
        />
        <VersionItem
          title="Nullable attributes"
          description="Working with missing data is now more efficient in TileDB and better aligns with modern dataframe conventions. Both fixed and variable-length attributes now support nullables. By default, fill values for nullable attributes will be null."
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Embedded v2.1"
        date={{
          year: 2020,
          month: 10,
          day: 5,
        }}
        linkLabel="Learn more"
        link="https://github.com/TileDB-Inc/TileDB/releases/tag/2.1.0"
      >
        <VersionItem
          title="Updated cloud object storage support"
          description="This release adds TileDB support for read-ahead caching on cloud-storage backends and upgrades cloud provider SDKs to their latest versions within TileDB."
        />
        <VersionItem
          title="Configurable concurrency"
          description="Version 2.1 adds options for configuring concurrency levels and provides appropriate defaults.          "
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Cloud v1.1"
        date={{
          year: 2020,
          month: 5,
          day: 5,
        }}
        linkLabel="Sign up"
        link="https://console.tiledb.com/signup"
      >
        <VersionItem
          title="Hosted Jupyter notebooks"
          description="Run Jupyter notebooks in seconds right from TileDB Cloud and jumpstart your work with examples on common use cases. "
        />
        <VersionItem
          title="Serverless Task Graphs"
          description="This release introduces task graphs to run parallel user-defined functions -completely serverless. Enjoy ease-of-use, superior scalability, and performance while eliminating idle compute costs."
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Embedded v2.0"
        date={{
          year: 2020,
          month: 5,
          day: 5,
        }}
        linkLabel="Learn more"
        link="https://github.com/TileDB-Inc/TileDB/blob/2.0.0/HISTORY.md#tiledb-v200-release-notes"
      >
        <VersionItem
          title="Generalized Dataframe Support"
          description="TileDB 2.0 adds heterogeneous and string dimensions, now fully supporting dataframe use cases."
        />
        <VersionItem
          title="Revamped R API"
          description="The R API in TileDB has been revamped for better performance and ease-of-use and a key building block for integrations to R packages such as tidyverse and Bioconductor."
        />
        <VersionItem
          title="Extended Public Cloud Support"
          description="TileDB 2.0 adds support for Google Cloud Storage and Azure Blob Storage to the existing Amazon S3 support."
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Cloud"
        date={{
          year: 2019,
          month: 11,
          day: 11,
        }}
        linkLabel="Sign up"
        link="https://console.tiledb.com/signup"
      >
        <VersionItem
          title="Share with anyone"
          description="Today we are releasing TileDB Cloud, a cloud service that allows you to register and share your TileDB arrays with anyone in the cloud. You can define access policies and audit all access using simple and intuitive array semantics."
        />
        <VersionItem
          title="Serverless"
          description="TileDB Cloud allows you to perform serverless SQL and Python UDFs, avoiding the deployment hassle and enjoying tremendous scalability."
        />
        <VersionItem
          title="Pay-as-you-go"
          description="TileDB Cloud comes with a pay-as-you-go model, allowing you to greatly reduce your operational costs."
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Genomics"
        date={{
          year: 2019,
          month: 11,
          day: 11,
        }}
        linkLabel="Learn more"
        link="https://docs.tiledb.com/main/use-cases/genomics"
      >
        <VersionItem
          title="A scalable variant store"
          description="We are releasing an open-source genomics customization called TileDB-VCF, which allows you to store arbitrarily large gVCF datasets as sparse 2D arrays in TileDB. With TileDB-VCF, you can solve the N+1 problem, achieving linear storage scalability, regardless your dataset size and number of updates."
        />
        <VersionItem
          title="40% space savings and scalability"
          description="TileDB-VCF reduces your collection of single-sample BCF files by 40%, leads to fast and scalable analysis on the cloud, and allows you to take advantage of the Data Science ecosystem via TileDB’s numerous integrations."
        />
      </UpdatesItem>
      <UpdatesItem
        title="Embedded SQL"
        date={{
          year: 2019,
          month: 11,
          day: 11,
        }}
        linkLabel="Learn more"
        link="https://docs.tiledb.com/developer/api-usage/embedded-sql"
      >
        <VersionItem
          title="Embeddable MariaDB"
          description="You can now enjoy the powerful integration of TileDB with MariaDB as an embeddable library, without having to install and maintain a MariaDB server, thus substantially simplifying your software builds."
        />
      </UpdatesItem>
      <UpdatesItem
        title="MariaDB Connector"
        date={{
          year: 2019,
          month: 11,
          day: 11,
        }}
        linkLabel="Learn more"
        link="https://docs.tiledb.com/developer/mariadb/quickstart"
      >
        <VersionItem
          title="Pluggable storage engine"
          description="We added TileDB as a pluggable storage engine to MariaDB. You are now able to run fast SQL queries via your MariaDB server on TileDB arrays, stored either on premises or on the cloud (e.g., Amazon S3)."
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Geospatial"
        date={{
          year: 2019,
          month: 11,
          day: 11,
        }}
        linkLabel="Learn more"
        link="https://docs.tiledb.com/main/use-cases/geospatial"
      >
        <VersionItem
          title="Integrations"
          description="TileDB is integrated with PDAL, GDAL, and Rasterio, providing you a way to store your geospatial data in a single, cloud-optimized format."
        />
      </UpdatesItem>
      <UpdatesItem
        title="TileDB Core v1.7"
        date={{
          year: 2019,
          month: 11,
          day: 11,
        }}
        linkLabel="Learn more"
        link="https://github.com/TileDB-Inc/TileDB/blob/release-1.7.0/HISTORY.md"
      >
        <VersionItem
          title="Array metadata support"
          description="We added support for key-value array metadata that you can attach to any array."
        />
        <VersionItem
          title="Overhauling KV store"
          description="We removed KV objects that used to implement key-value functionality. We will soon introduce support for string dimensions, which will realize a full-fledged key-value store via TileDB arrays, allowing multi-dimensional and prefix-based string search."
        />
      </UpdatesItem>
    </main>
    <Footer />
  </IndexLayout>
);

export default Updates;
